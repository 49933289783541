import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { Link } from "gatsby"
import Product from "../components/product"

export default function Template({
                                   data, // this prop will be injected by the GraphQL query below.
                                    pageContext
                                 }) {
  console.log('data', data)
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { prev, next } = pageContext
  return (
    <Layout title={frontmatter.title}>
      <nav id="nav-single">
        <h3 className="assistive-text">Post navigation</h3>
        {prev && <span className="nav-previous"><Link to={prev.frontmatter.path}>
            <span className="meta-nav">←</span> Previous
          </Link></span>
        }
        {next && <span className="nav-next"><Link to={next.frontmatter.path}>
          Next <span className="meta-nav">→</span>
        </Link></span>
        }
      </nav>
      <Product {...frontmatter}  html={html}/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        sku
        price
      }
    }
  }
`
